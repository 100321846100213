import { useEffect, useState } from "react";
import {
  getUserList,
  toggleActiveUser,
  updateUserBranch,
  updateUserMembershipType,
} from "../../services/users/UsersService";
import styled from 'styled-components';
import Button from "react-bootstrap/Button";

export default function FiltersBar(props) {
  
  const { gyms } = props;
  const { membershipTypes, filterUsers, toggleShowInactives } = props;

  const [ gymsOptions , setGymOptions ] = useState([]);
  const [ memberOptions, setMemberOptions ] = useState([]);
  const [ nameQuery, setNameQuery ] = useState('');

  const search = () => {
    filterUsers(nameQuery);
  };

  useEffect(() => {
    if (membershipTypes && membershipTypes.length > 0) {
      const options = membershipTypes.map(membership => {
        const data = membership.data();
        const id = membership.id;
        return <Option key={id} value={id}>{data.name}</Option>;
      });
      options.unshift(<Option key='-1' value='-1'>TODAS</Option>)
      setMemberOptions([...options ]);
    }
  }, [membershipTypes]);

  useEffect(() => {
    if (gyms && gyms.length > 0) {
      const options = gyms.map(gym => {
        const data = gym.data();
        const id = gym.id;
        return <Option key={id} value={id}>{data.name}</Option>;
      });
      options.unshift(<Option key='-1' value='-1'>TODAS</Option>)
      setGymOptions([...options ]);
    }
  }, [gyms]);
  return <Container>
    <span>
      <input type='checkbox' onChange={toggleShowInactives}/> Mostrar inactivos
    </span>
    <span>
      Nombre: <input onChange={(e) => setNameQuery(e.target.value)} type='text' />
      <span>
        <Button size='sm' onClick={()=>search()}>Buscar</Button>
      </span>
    </span>
  </Container>
};

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 80%;
  justify-content: space-around;
`;

const Select = styled.select`
  font-size: 16px;
`;

const Option = styled.option`
  font-size: 16px;
`;

const Input = styled.input`
  font-size: 16px;
`;