import React,{useState, createContext} from 'react';

export const FullContext = createContext();

export const FullProvider = (props) =>{
  const[user, setUser] = useState(null);
  const[allData, setAllData] = useState({});
  const[userChecker, setUserChecker] = useState(null);

  return(
    <FullContext.Provider value={[user, setUser, allData, setAllData, userChecker, setUserChecker]}>
      {props.children}
    </FullContext.Provider>
  );
}