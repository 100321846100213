import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { FullContext } from '../../FullContext';
import Table from 'react-bootstrap/Table';

import { getUsersActive } from '../../services/users/UsersService';

import {HNormal} from '../ui/Headers';
import Bubble from '../ui/Bubble';
import {BtnP, BtnS, BtnPFull} from '../ui/Buttons';
import { InputLogin } from '../ui/Inputs2';
import styled from 'styled-components';


function QuickTest (){
  const navigate = useNavigate();
  const [user, setUser] = useContext(FullContext);
  const [allData, setAllData] = useContext(FullContext);

  const [allUsers, setAllUsers] = useState([]);
  const [filteredWord, setFilteredWord] = useState("");

  useEffect(() => {
    
  }, []);

  const goActives = async () => {
    let result = await getUsersActive();
    //setAllUsers(await getUsersActive());
    //console.log(result);
    setAllUsers(result.docs);
  }

  const getFilteredUsers = () =>{

    let result = allUsers;
    if (filteredWord.length >= 3){
      let toSearch = filteredWord.toLowerCase();
      result = allUsers.filter(user =>( user.data().name.toLowerCase().includes(toSearch) 
        || user.data().phone.includes(toSearch)
        || user.data().email.toLowerCase().includes(toSearch)
      ));
    }else{
      result = [];
    }
    return result;
  }

  const getMessageFooter = () =>{
    let result = "favor de escribir algo que buscar, minimo 3 caracteres";
    if (filteredWord.length >= 3){
      result = "";
      if (getFilteredUsers().length == 0){
        result = "sin coincidencias";
      }
    }

    return result;
  }


  return (
    <div>
      <HNormal />
      <Bubble withHeader="Quick Test">
        
      <BtnP click={() =>navigate('/login')} label={"salir"}></BtnP>
      {allUsers.length > 0?"Lista Cargada":<BtnS click={() =>goActives()} label={"Cargar Activos"}></BtnS>}
      
      <InputLogin tType="text" 
          tValue={filteredWord} 
          oChange={setFilteredWord}
          placeholder="Palabra a buscar en todo"
        />
        
      <TableContainer>
        <Table striped bordered hover>
          
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Correo</th>
              <th>Teléfono</th>
              <th>ID</th>
            </tr>
          </thead>
          <tbody>
          {
            getFilteredUsers().map((user) => {
              return <tr key={user.id}>
                <Td>{user.data().name}</Td>
                <Td>{user.data().email}</Td>
                <Td>{user.data().phone}</Td>
                <Td>{user.id}</Td>
              </tr>
            })
          }
          </tbody>
          
        </Table>
      </TableContainer>

      <h5>{getMessageFooter()}</h5>
        
      </Bubble>

      
    </div>
  );
}

export default QuickTest;

const TableContainer = styled.div`
  width: 70%;
  background-color: white;
`;
const Td = styled.td`
  font-size: 14px;
`;