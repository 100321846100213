import React from 'react';

const ERRORS_MAP={
  'auth/claims-too-large': 'Reduce reclamos y vuelve a intentar.',
  'auth/email-already-exists': 'Correo en uso, elige otro.',
  'auth/email-already-in-use': 'Correo en uso, elige otro.',
  'auth/id-token-expired': 'Token de ID expirado, inicia sesión de nuevo.',
  'auth/id-token-revoked': 'Token de ID revocado, inicia sesión de nuevo.',
  'auth/invalid-claims': 'Reclamos inválidos, verifica y corrige.',
  'auth/invalid-email': 'Correo inválido, ingrésalo de nuevo.',
  'auth/invalid-id-token': 'Token de ID inválido, inicia sesión de nuevo.',
  'auth/user-disabled': 'Cuenta desactivada, contacta al administrador.',
  'auth/user-not-found': 'Usuario no encontrado, verifica la información.',
  'auth/wrong-password': 'Contraseña incorrecta, intenta de nuevo.'
  }

  export const getMessage = (errorCode) =>{
    let result = "Algo salio mal, favor de reportar el siguiente codigo: ";
    ERRORS_MAP[errorCode]?result = ERRORS_MAP[errorCode]: result += errorCode;
    return result;
  }