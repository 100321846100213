import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { FullContext } from '../../FullContext';
import styled from 'styled-components';

import {COLORS} from '../ui/Colors';
import { HNormal } from '../ui/Headers';
import Bubble from '../ui/Bubble';
import QRCode from 'qrcode.react';
import {Modal, Button} from 'react-bootstrap';
import moment from 'moment';

function EntryDetail(){
  const navigate = useNavigate();
  const [allData, setAllData] = useContext(FullContext);
  const [user, setUser] = useContext(FullContext);

  const [diff, setDiff] = useState(null);
  const [initial, setInitial] = useState(null);
  const [show, setShow] = useState(false);

  const tick = () => {
    if (initial <= new Date()){
      handleShow();
      return;
    }
    setDiff(new Date(initial - new Date())) 
  };
  const start = () => { 
    setInitial(+new Date());
  } 
  useEffect(() => {
    if (initial) {
      requestAnimationFrame(tick);
    }
  }, [initial]);
  useEffect(() => {
    if (diff) {
      requestAnimationFrame(tick);
    }
  }, [diff]);

  useEffect(() => {
    checkData();
  }, []);

  const handleClose = () => {
    setShow(false);
    navigate(-1);
  }
  const handleShow = () => setShow(true);

  const checkData = async () =>{
    if (allData && allData.entrySelected){
      let limit = moment(allData.entrySelected.data().created_at.toDate()).add(allData.entrySelected.data().timeout, 'm');
      setInitial(limit.toDate());
    }
  }

  const getBarcodeZone = () =>{
    let result = <div></div>;
    if (allData && allData.entrySelected){
      result= <QRCode value={user.data().phone} size={180} style={{margin: '0.5rem' }}/>
    }
    return result;
  }

  return(
    <Base>
      <HNormal withBack/>

      <Bubble withHeader="Acceso"> 

        {getBarcodeZone()}
        <LabelTitle>{timeFormat(diff)}</LabelTitle>
        <em style={{ color: 'red', fontSize: 16, margin: 15, background: '#ffeaa7' }}><b>¡CUIDADO!:</b> EL QUE OTRA PERSONA UTILICE TU QR<br/> PUEDE GENERARTE UNA <b>FUERTE MULTA.</b></em>
      </Bubble>
      
      

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Acceso</Modal.Title>
        </Modal.Header>
        <Modal.Body>{"Ha expirado este acceso"}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

    </Base>
  );
}

export default EntryDetail;

const Base = styled.div`
  
`;

const LabelTitle = styled.div`
  color: ${COLORS.blue_dark};
  font-size: 2rem;
  font-weight: bold;
`;

const timeFormat = (date) => {
  if (!date) return "00:00";

  let mm = date.getUTCMinutes();
  let ss = date.getSeconds();
  let cm = Math.round(date.getMilliseconds() / 10);

  mm = mm < 10 ? "0"+mm :  mm;
  ss = ss < 10 ? "0"+ss :  ss;
  cm = cm < 10 ? "0"+cm:  cm;

  return `${mm}:${ss}`;
};

const Tittled = styled.label`
  margin: 1.5rem 0rem 0.5rem 0rem;
  width: auto;
  font-size: 1.5em;
  font-weight: bold;
  color: ${COLORS.blue_dark};
`;
