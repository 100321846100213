import React, {useState} from 'react';
import { FaChevronLeft, FaBars, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import ImagenFull from '../../header_logo_full.png';
import {COLORS} from '../ui/Colors';

export const HLogin = (props) => {
  
  const navigate = useNavigate();

  const goBack = () =>{
    navigate(-1);
  }

  const getBack = () =>{
    let result = <FaChevronLeft size={'2em'} color={'transparent'}/>;
    if (props.withBack){
      if (props.specialBack){
        result = <FaChevronLeft size={'1.5em'} color={COLORS.white} onClick={() =>props.specialBackF()}/>;
      }else{
        result = <FaChevronLeft size={'1.5em'} color={COLORS.white} onClick={() =>goBack()}/>;  
      }
      
    }
    return result;
  }

	return (
		<HeaderN className="container-fluid">
      <div className="row g-0">
        <div className="col-2" >
        <Logo >
          {getBack()}
        </Logo>
        </div>
        <div className="col-8">
          <Logo >
            <img src={ImagenFull} />
          </Logo>
        </div>
        <div className="col-2"/>
      </div>
    </HeaderN>
	);
}

export const HNormal = (props) => {
  
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  const goBack = () =>{
    navigate(-1);
  }

  const clickItem = () =>{
    setShowMobileMenu(!showMobileMenu);
    if (props.withLogout){
      props.logout();
    }
  }
  const clickItemDatos = () =>{
    setShowMobileMenu(!showMobileMenu);
    props.account();
  }

  const getBack = () =>{
    let result = <FaChevronLeft size={'2em'} color={'transparent'}/>;
    if (props.withBack){
      if (props.specialBack){
        result = <FaChevronLeft size={'1.5em'} color={COLORS.white} onClick={() =>props.specialBackF()}/>;
      }else{
        result = <FaChevronLeft size={'1.5em'} color={COLORS.white} onClick={() =>goBack()}/>;  
      }
      
    }
    return result;
  }

  const getLogout = () =>{
    let result = <FaChevronLeft size={'2em'} color={'transparent'}/>;
    if (props.withLogout){
      result = <Logo onClick={() => setShowMobileMenu(!showMobileMenu)}>   
        {showMobileMenu ? <FaTimes size={'1.5em'} color={COLORS.white}/> : <FaBars size={'1.5em'} color={COLORS.white}/>}
      </Logo>
      
    }
    return result;
  }

	return (
		<HeaderN className="container-fluid">
      <div className="row g-0">
        <div className="col-2" >
        <Logo >
          {getBack()}
        </Logo>
        </div>
        <div className="col-8">
          <Logob >
            <img src={ImagenFull} />
            
          </Logob>
        </div>
        <div className="col-2">
          {getLogout()}
        </div>
      </div>
      {/* {window.location.hash = 'no-back-button'}
      {window.location.hash = 'Again-No-back-button'}
      {window.onhashchange = function () {window.location.hash = 'no-back-button'}} */}
      <Menu open={showMobileMenu}>
      <MenuItem onClick={() => clickItemDatos()}>
            <div>
              Mis Datos
            </div>
        </MenuItem>
        <MenuItem onClick={() => clickItem()}>
            <div>
              Cerrar Sesion
            </div>
        </MenuItem>
      </Menu>
    </HeaderN>
	);
}

const Header = styled.div`
  height: 3rem;
  background: ${COLORS.blue};
  padding: 0;
  margin: 0;
`;
const HeaderN = styled(Header)`
  background: ${COLORS.blue_dark};
`;

const Logob = styled.div`
  height: 3rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background: #252525;
  img {
    max-width: 80%;
    max-height: 3rem;
    //width: 70%;
    height: auto;
    vertical-align: top;
    //background: #252525
  }
`;

const Header24 = styled.div`
  background: ${COLORS.blue};
  color: ${COLORS.blue_dark};
  font-weight: bold;
  font-style: italic;
  margin-left: 0.5rem;
`;

const Logo = styled.div`
  height: 3rem;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background: #252525;
  img {
    max-width: 80%;
    max-height: 3rem;
    //width: 70%;
    height: auto;
    vertical-align: top;
    //background: #252525
  }
`;

const Menu = styled.ul`
  height: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;

  @media screen {
    background-color: ${COLORS.blue_dark};
    position: absolute;
    height: 3rem;
    left: ${({ open }) => (open ? "0" : "-100%")}; //Import
    width: 100%;
    height: 90vh;
    
    flex-direction: column;
    align-items: center;
    justify-content: start;
    transition: 0.5s all ease;
  }
`;

const MenuItem = styled.li`
  height: 100%;
  color: ${COLORS.white};
  font-size: 1.25em;
  font-weight: bold;
  cursor: pointer;
  transition: 0.5s all ease;

  @media screen  {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

