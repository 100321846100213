import { db } from '../Firebase';
import { collection, getDocs, query, where} from 'firebase/firestore';

export const getActiveGyms = async () => {
  const gymsRef = collection(db, "gym");
  let q = query(gymsRef, where("active", "==", true));
  const res = await getDocs(q);
  return res.docs;
};

