import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { FullContext } from '../../FullContext';

import '../../App.css';
import {Alert, Form } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import {auth, db} from '../../services/Firebase';
import {signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';
import { getDoc, doc, collection, getDocs, query, where } from 'firebase/firestore';

import { HLogin } from '../ui/Headers';
import styled from 'styled-components';
import {COLORS} from '../ui/Colors';
import Bubble from '../ui/Bubble';
import { InputLogin } from '../ui/Inputs2';
import {BtnP, BtnS} from '../ui/Buttons';
import {ModalSimple} from '../ui/Modal';
import { getMessage } from '../util/messages';
import { recoverPassword, getUserInfo } from '../../services/users/UsersService';

function Login(){
  const navigate = useNavigate();
  const [user, setUser] = useContext(FullContext);
  const [userChecker, setUserChecker] = useContext(FullContext);

  const [name, setName] = useState('');
  const [pass, setPass] = useState('');
  const [messageAlert, setMessageAlert] = useState('');
  const [messageAlertD, setMessageAlertD] = useState('');
  const [show, setShow] = useState(false);
  const [phase, setPhase] = useState(1);
  const [loading, setLoading] = useState(false);
  const [authUser, authLoading] = useAuthState(auth);

  const googleImg = "https://pbs.twimg.com/profile_images/988272404915875840/lE7ZkrO-_400x400.jpg";
  const faceImg = "https://seeklogo.com/images/F/facebook-icon-circle-logo-09F32F61FF-seeklogo.com.png";
  const emailImg = "https://manuals.plus/wp-content/uploads/2022/02/46-8.png";

  const providerG = new GoogleAuthProvider();

  const goRegister = () =>{
    setUser({});
    navigate("/register2");
  }

  useEffect(() => {
    if( !authLoading && authUser) {
      checkUserInfo(authUser.uid)
    }
  }, [authLoading]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const checkUserInfo = async (userId) =>{
    if (user){
      navigate('/main');
      return;
    }
    try{
      const result = await getUserInfo(userId);
      setUser(result);
      navigate('/main');
    }catch( error ) {
      console.log(error);
      setMessageAlertD('error: '+getMessage(error.code));
    }
    
  }

  const checkLogin = async () =>{
    if (name.trim().length < 3){
      setMessageAlert('Necesitas un usuario valido');
      return;
    }
    if (pass.length < 3){
      setMessageAlert('Necesitas una contraseña valida');
      return;
    }
    setLoading(true);
    try {
      const res = await signInWithEmailAndPassword(auth, name.trim(), pass);
      let docRef = doc(db, "user", res.user.uid);
      const res2 = await getDoc(docRef);
      setUser(res2);
      let docRefChecker = query(collection(db, "user_checker"), where("user", "==", res2.id));
      const res3 = await getDocs(docRefChecker);
      const { docs } = res3;
      if (docs.length !== 0){
        setUserChecker(res3.docs[0]);
        navigate("/checker/"+res3.docs[0].data().gym_name);
      }else{
        navigate("/main");
      } 
    }
    catch( error ) {
      console.log(error);
      setMessageAlertD('error: '+getMessage(error.code));
    }
    finally {
      setLoading(false);
    }
  }
  const checkGoogle = () =>{
    signInWithPopup(auth, providerG)
    .then((res) => {
      let docRef = doc(db, "user", res.user.uid);
      getDoc(docRef)
        .then(res2 =>{
          if (res2.exists()){
            setUser(res2);
            navigate("/main");
          }else{
            let newUser = {}
            newUser.id = res.user.uid;
            newUser.email = res.user.email;
            setUser(newUser);
            navigate("/register2");
          }
        });
    }).catch((error) => {
      console.log(error.message);
    });
  }

  const loaderZone = () =>{
    return <div className="container" style={{marginTop:"1rem"}}>
      <Spinner animation="border" variant="info"  />
    </div>;
  }

  const onRecoverPassword = async () => {
    // Expresión regular para validar el correo electrónico
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Comprobación utilizando la expresión regular
    if (!emailPattern.test(name)) {
      setMessageAlertD('Correo no válido');
      return;
    }
    try {
      await recoverPassword(name);
      setMessageAlert(`Correo enviado a ${name} para recuperar contraseña`);
    } catch(err) {
      setMessageAlertD('error: '+getMessage(err.code));
    }
  };

  const correoZone = () =>{
    let actionZone = <div className="container" style={{marginTop:"1rem"}}>  
      <BtnP click={() =>goRegister()} label={"Registro"}></BtnP>
      <BtnS click={() =>checkLogin()} label={"Entrar"}></BtnS>
    </div>;

    return <div className="container" >

      <div>
        <WellcomeDesc>Escribe tu Usuario y Contraseña de acceso de plataforma</WellcomeDesc>
      </div>

      <div className="container" >
        <Form >
          
          <InputLogin tType="text" 
            tValue={name} 
            oChange={setName}
            placeholder="Usuario"
          />
          <InputLogin tType="password" 
            tValue={pass} 
            oChange={setPass}
            placeholder="Contraseña"
          />
        </Form>
      </div>
      <RecoverPass onClick={onRecoverPassword}>Olvidé mi contraseña</RecoverPass>
      {loading? loaderZone():actionZone}
    </div>;
  }
  const initalZone = () =>{
    return <div >
      <div>
        <WellcomeT>¡Bienvenido!</WellcomeT>
      </div>
      <div>
        <WellcomeDesc>Elige el método a utilizar para acceder a tu cuenta</WellcomeDesc>
      </div>
      <div>
        <ImagePointer src={googleImg} onClick={() =>checkGoogle()} />
        <ImagePointer src={faceImg} onClick={() =>handleShow()} />
        <ImagePointer src={emailImg} onClick={() =>setPhase(1)} />
      </div>
      
    </div>
  }

  return (
    <Base>
      {/*<HLogin  withBack={phase!==0} specialBack specialBackF={() =>{setPhase(0)}} />*/}
      <HLogin />
      <div className="container" >
        <Alert show={(messageAlert.length > 3)} variant='warning' dismissible onClose={() => setMessageAlert('')}>
          {messageAlert}
        </Alert>
        <Alert show={(messageAlertD.length > 3)} variant='danger' dismissible onClose={() => setMessageAlertD('')}>
          {messageAlertD}
        </Alert>
      </div>
      
      <Bubble withHeader="Acceso">
        {phase === 0?initalZone():correoZone()}
        
      </Bubble> 

      <ModalSimple 
        show={show} 
        handleClose={handleClose}
        title={"Acceso"}
        body={"Esta opcion estara disponible pronto"}/>
    </Base>
    
  );
}

export default Login;

const Base = styled.div`
`;

const Access = styled.label`
  margin: 1.5rem 0rem 0.5rem 0rem;
  width: auto;
  font-size: 1.5em;
  font-weight: bold;
  color: ${COLORS.blue_dark};
`;

const WellcomeT = styled.label`
  border-radius: 20px;
  padding: 3px 8px;
  margin: 1rem;
  width: auto;
  border: 4px solid ${COLORS.green};
  font-size: 2em;
  font-weight: bold;
  color: ${COLORS.green};
`;

const WellcomeDesc = styled.label`
  margin: 1rem;
  max-width: 300px;
  width: 90%;
  color: ${COLORS.blue_dark};
`;

const ImagePointer = styled.img`
  width: 3rem;
  margin: 0rem 1rem;
  cursor: pointer;
`;

const RecoverPass = styled.span`
  cursor: pointer;
`;