import { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import { Toaster, toast } from 'sonner'

import {
  getUserList,
  toggleActiveUser,
  updateUserBranch,
  updateUserMembershipType,
  updateUserField,
} from "../../services/users/UsersService";
import { getMembershipTypes } from "../../services/users/MembershipService";
import { getActiveGyms } from "../../services/gyms/GymsService";
import {COLORS} from '../ui/Colors';
import styled from 'styled-components';
import ListDropdown from "../ui/ListDropdown";
import EditableField from '../ui/EditableField';
import FiltersBar from './filtersBar';
import { isPhoneNumber } from '../util/validations';

export default function ActivateList(props){
  
  const [ users, setUsers ] = useState([]);
  const [ gyms, setGyms ] = useState([]);
  const [ membershipTypes, setMembershipTypes ] = useState([]);
  const [ lastVisible, setLastVisible ] = useState(null);
  const [ updatedId, setUpdatedId ] = useState(0);
  const [ showInactives, setShowInactives ] = useState(false);
  
  const retrieveData = async () => {
    const calls = [getActiveGyms(), getMembershipTypes() ];
    const [gyms, membershipTypes ] = await Promise.all(calls);
    //setUsers(users);
    setGyms(gyms);
    setMembershipTypes(membershipTypes);
    filterUsers('');
  };

  useEffect(() => {
    retrieveData();
    filterUsers('');
  }, []);

  useEffect(() => {
    setUsers([]);
    setLastVisible(null);
    retrieveData();
  }, [showInactives]);
  
  const filterUsers = async (nameQuery) => {
    const { usersData , last } = await getUserList(10, nameQuery, showInactives, lastVisible);
    setLastVisible(last);
    setUsers([...users, ...usersData]);
  };

  const generateActiveSpan = (isActive)  => {
    return isActive ? <Active> Activo </Active> :
      <Inactive> Inactivo </Inactive>;
  };

  const generateActivateButton = (user) => {
    const { isActive, id } = user;
    if (updatedId === id) {
      return <Spinner animation="border" />; 
    }
    const onClick = (userEvent) => toggleActivateUser(userEvent);
    return isActive ? <Button size='sm' onClick={()=>onClick(user)}>Desactivar</Button>
      :<Button size='sm' onClick={()=>onClick(user)}>Activar</Button>;
  };

  const toggleActivateUser = async (user) => {
    setUpdatedId(user.id);
    await toggleActiveUser(user);
    setUpdatedId(0);
    await retrieveData();
    toast.success('El usuario ha sido actualizado');
  };

  const showOptions = (elements, user, onClickSave, field='') => {
    return <ListDropdown
      elements={elements}
      user={user}
      field={field}
      onSave={onClickSave}
    />
  };

  const onCLickSaveGyms = async(element, user) => {
    const { id:gymId } = element;
    const data = element.data();
    const branch = {
      name: data.name,
      id: gymId,
    };
    await updateUserBranch(branch, user);
    await retrieveData();
    toast.success('El usuario ha sido actualizado');
  };

  const onClickSavePhone = async(phone, user) => {
    if (!isPhoneNumber(phone)) {
      toast.warning('El número de teléfono ingresado no es válido.');
      return;
    }
    await updateUserField(user, 'phone', phone);
    await retrieveData();
    toast.success('El usuario ha sido actualizado');
  };

  const onCLickSavMembershipTypes = async(element, user) => {
    const { id:mebershipId } = element;
    const data = element.data();
    const membership = {
      name: data.name,
      id: mebershipId,
    };
    await updateUserMembershipType(membership, user);
    await retrieveData();
  };

  const toggleShowInactives = async () => {
    setShowInactives(!showInactives);
  };

  const loadNextPage = async () => {
    console.log(lastVisible);
    retrieveData();
  };
  
  return <Container>
    <h2>Usuarios EDGym App</h2>
    <FiltersBar 
      filterUsers={filterUsers} 
      gyms={gyms}
      toggleShowInactives={toggleShowInactives}
      membershipTypes={membershipTypes} 
    />
    <TableContainer>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Teléfono</th>
            <th>Sucursal</th>
            <th>Tipo Membresia</th>
            <th>Estatus</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {
          users.map((user) => {
            return <tr key={user.id}>
              <Td>{user.name}</Td>
              <Td>{user.email}</Td>
              <Td>
                <EditableField 
                  user={user}
                  field={'phone'}
                  onSave={onClickSavePhone}
                />
              </Td>
              <Td>{showOptions(gyms, user, onCLickSaveGyms, 'branch')}</Td>
              <Td>{user.membership ? user.membership.name : showOptions(membershipTypes, user, onCLickSavMembershipTypes)}</Td>
              <Td>{generateActiveSpan(user.isActive)}</Td>
              <Td>{generateActivateButton(user)}</Td>
            </tr>
          })
        }
        </tbody>
      </Table>
    </TableContainer>
    <Button onClick={loadNextPage}>Cargar mas...</Button>
    <Toaster />
  </Container> 
}

const Inactive = styled.span`
  color: ${COLORS.red};
  font-size: 16px;
`;

const Active = styled.span`
  color: ${COLORS.green};
  font-size: 16px;
`;

const TableContainer = styled.div`
  width: 70%;
  background-color: white;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
`;

const Td = styled.td`
  font-size: 14px;
`;