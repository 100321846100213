import React, {useState, useContext, useEffect} from 'react';

function GymInfo (props){
  const [showAddress, setShowA] = useState(false);


  return (
    <div className="card border-primary mb-2" style={{width:"18rem"}} >
      <div className="card-header">{props.info?.data().name}</div>
      <div className="card-body">
        <h6 className="card-title">{props.info?.data().address}</h6>

        <div className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button className={showAddress?"accordion-button":"accordion-button collapsed"} type="button" data-bs-toggle="collapse" 
                data-bs-target="#collapseOne" aria-expanded={showAddress} aria-controls="collapseOne"
                onClick={() =>{setShowA(!showAddress)}}>
                Contacto
              </button>
            </h2>
            <div id="collapseOne" className={showAddress?"accordion-collapse collapsed":"accordion-collapse collapse"} 
              aria-labelledby="headingOne" data-bs-parent="#accordionExample" >
              <div className="accordion-body">
                <p>{props.info?.data().c_phone}</p>
                {props.info?.data().c_email}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="card-footer">{props.info?.data().schedule}</div>
    </div>
  );
}

export default GymInfo;