import React, {useState, createContext} from 'react';
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import logo from '../../logo.svg'

function CHeader(props){
  const navigate = useNavigate();

  const goBack = () =>{
    navigate(-1);
  }

  const getBack = () =>{
    let result = <BsArrowLeft size={'2em'} color={'transparent'}/>;
    if (props.withBack){
      if (props.specialBack){
        result = <BsArrowLeft size={'2em'} color={'white'} onClick={() =>props.specialBackF()}/>;
      }else{
        result = <BsArrowLeft size={'2em'} color={'white'} onClick={() =>goBack()}/>;  
      }
      
    }
    return result;
  }
  const getTitle = () =>{
    let result = <div style={{margin: '1rem' }}/>;
    if (props.title){
      if (props.tinyTitle){
        result = <h4 style={{margin: '1rem' }}>{props.title}</h4>
      }else{
        result = <h3 style={{margin: '1rem' }}>{props.title}</h3>
      }
    }
    return result;
  }
  
  return(
    <div className="App">
      <div className="navbar navbar-dark bg-primary">
        <div className="container-fluid" style={{margin: '1rem 1rem' }}>
          {getBack()}
          <h3 style={{color:"white"}}>EveryDay Gym</h3>
          <BsArrowLeft size={'2em'} color={'transparent'}/>
        </div>
      </div>
      {getTitle()}
      {props.children}
    </div>
  );
}

export default CHeader;