import React, {useState, useContext, useEffect} from 'react';
import { FullContext } from '../../FullContext';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";

import {Alert, Button, Modal, Spinner } from 'react-bootstrap';
import {auth, db} from '../../services/Firebase';
import { getDoc, doc, collection, getDocs, query, where } from 'firebase/firestore';
import { getDatabase, ref, set } from "firebase/database";

import moment from 'moment';
import { HLogin } from '../ui/Headers';
import Bubble from '../ui/Bubble';

function Checker(){
  const [userChecker, setUserChecker] = useContext(FullContext);

  const [watching, setWatching] = useState(true);
  const [isCorrect, setCorrect] = useState("");
  const [message, setMessage] = useState("");
  const [timeOut, setTimeOut2] = useState(0);

  const db2 = getDatabase();

  /*useEffect(() => {
    setTimeout(() => {
      setTimeOut2((timeOut) => timeOut - 1);
    }, 1000);
  }, [timeOut])*/

  useEffect(() => {
    let timer = setTimeout(() => {
      setTimeOut2((timeOut) => timeOut - 1);
      if (timeOut === 1){
        closeDoor();
        //console.log("mando pulso cerrar...");
      }
    }, 1000);

    return () => clearTimeout(timer)
  }, [timeOut]);

  const onUpdateScan = (err, result) =>{
    if (result){
      setWatching(false);
      setCorrect("");
      setMessage("");
      let cleantext = result.text.replaceAll('.', '').replaceAll(':', '').replaceAll(' ', '').replaceAll('/', '');
      findAccess(cleantext);
    }
    if (err){

    }
  }
  const findAccess = (id) =>{
    getDoc(doc(db, "entry", id))
    .then(res=>{
      if (res.exists()){
        setCorrect(res.data().user_name);
        let day = moment(res.data().created_at.toDate()).add(res.data().timeout, 'm');
        let today = moment(new Date());
        if (day < today){
          //felpaste
          setMessage("codigo QR ha expirado");
          return;
        }
        if (res.data().gym !== userChecker.data().gym){
          //felpaste
          setMessage("codigo QR no pertenece a esta sucursal");
          return;
        }
        set(ref(db2, '/'), {
          led: 1
        });
        setTimeOut2(10);

      }else{
        //felpaste
        setMessage("codigo QR no valido");
      }
    })
    
    setWatching(true);
  }

  const closeDoor = () =>{
    set(ref(db2, '/'), {
      led: 0
    });
    setMessage("");
    setCorrect("");
  }

  const getReaderZone = () =>{
    if (!watching){
      return <Spinner animation="grow" style={{ width: '4rem', height:'4rem', margin: '2rem' }}/>;
    }
    return <BarcodeScannerComponent
      width={400}
      height={400}
      onUpdate={onUpdateScan}
    />

  }

  const getMessageZone = () =>{
    let result = <div></div>;
    if (message !== ""){
      result = <h5>{message}</h5>;
    }
    return result;
  }

  const getCorrectZone = () =>{
    let result = <div></div>;
    if (isCorrect !== "" && message === ""){
      result = <h5>{"Acceso concedido - Bienvenido(a): "+isCorrect}</h5>
    }
    return result;
  }

  const getFlagtimerZone = () =>{
    let result = <div></div>;
      if (timeOut <= 0){
        result = <h4>Checking...</h4>;
      }
      if (timeOut >= 1){
        result = <h4>Clossing...</h4>;
      }
      if (timeOut >= 2){
        result = <h4>{"Open..."+timeOut}</h4>;
      }
      

    return result;
  }

  return (
    <div>
      <HLogin />
      <Bubble>
        <div className="container d-flex justify-content-center flex-wrap">
          {getMessageZone()}
        </div>
        <div className="container d-flex justify-content-center flex-wrap">
          {getCorrectZone()}
        </div>
        <div className="container d-flex justify-content-center flex-wrap">
          {getReaderZone()}
        </div>
        <div>
          {getFlagtimerZone()}
        </div>

      </Bubble>
      
    </div>
  );
}

export default Checker;