import React from 'react';
import styled from 'styled-components';
import {COLORS} from '../ui/Colors';

export const InputLogin = ({tType, tValue, oChange, placeholder}) => {
	return (
		<Input type={tType} value={tValue} 
      onChange={(event) =>{oChange(event.target.value)}}
      placeholder={placeholder}/>
	);
}

export const SelectorEntry = ({tValue, oChange, options}) =>{
  return (
    <Selector value={tValue}
      onChange={(event) =>{oChange(event.target.value)}}>
      <option value="">Seleccione sucursal</option>
        {options.map(gym =>(
          <option value={gym.id}>{gym.data().name}</option>
        ))}
    </Selector>
  );
}

const Input = styled.input`
  width: 90%;
  margin-bottom: 1rem;
	background: none;
	border-radius: 30px;
	border: 2px solid rgb(22,140,186);
	height: 70px;
	line-height: 40px;
	cursor: pointer;
	transition: all .3s ease;
	&:hover {
		border: 2px solid rgba(22,140,186, .50);
  }
  
  ::placeholder,
  ::-webkit-input-placeholder {
    font-style: italic;
  }
  :-ms-input-placeholder {
     color: red;
  }

`;


const Selector = styled.select`
  width: 90%;
  height: 70px;
  margin: 1rem 0rem;
  border-radius: 30px;
  border: 2px solid ${COLORS.blue_dark};
  color: ${COLORS.blue_dark};
  line-height: 40px;
  //font-size: 1.05rem;
  background: ${COLORS.white};
`;