import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { FullContext } from '../../FullContext';
import moment from 'moment';

import { Spinner, Alert, Modal, Button } from 'react-bootstrap';
import { BtnMain, BtnSinglePrincipal } from '../ui/Buttons';
import { SelectorEntry } from '../ui/Inputs';

import {db} from '../../services/Firebase';
import {collection, getDocs, query, where, addDoc} from 'firebase/firestore';

import styled from 'styled-components';
import {COLORS} from '../ui/Colors';
import { HNormal } from '../ui/Headers';
import Bubble from '../ui/Bubble';
import {BtnP} from '../ui/Buttons';


function Entrys(){
  const navigate = useNavigate();
  const [user, setUser] = useContext(FullContext);
  const [allData, setAllData] = useContext(FullContext);
  const [isLoading, setLoading] = useState(true);
  const [isNew, setNew] = useState(false);
  const [gyms, setGyms] = useState([]);
  const [entrys, setEntrys] = useState([]);
  const [gymSelected, setGymSelected] = useState("");
  const [messageAlert, setMessageAlert] = useState('');
  const [messageAlertD, setMessageAlertD] = useState('');
  const [show, setShow] = useState(false);

  const gymsRef = collection(db, "gym");
  const entrysRef = collection(db, "entry");
  

  useEffect(() => {
    getGyms();
    getEntrys();
  }, []);

  const getGyms = async () =>{
    let q = query(gymsRef, where("active", "==", true));
    getDocs(q)
      .then(res =>{
        setGyms(res.docs);
      });
  }
  const getEntrys = async() =>{
    let temporalLimit = moment();
    temporalLimit = temporalLimit.subtract(30, "minutes");
    let limit = temporalLimit.toDate();
    let q = query(entrysRef, where("user", "==", user.id), where("created_at", ">", limit));
    getDocs(q)
      .then(res =>{
        setEntrys(res.docs);
        setLoading(false);
      }).catch(error =>{
        setLoading(false);
        console.log(error);
      });
  }
  const createEntry = () =>{
    setLoading(true);
    let newEntry = {};
    let theGym = gyms.filter(gym => gym.id === gymSelected);

    newEntry.created_at = new Date();
    newEntry.user = user.id;
    newEntry.user_name = user.data().name;
    newEntry.gym = theGym[0].id;
    newEntry.gym_name = theGym[0].data().name;
    newEntry.timeout = 0.5;
    addDoc(collection(db, "entry"), newEntry)
      .then(res =>{
        handleClose();
        getEntrys();
      }).catch( error =>{
        handleClose();
        console.log(error);
      });
  }

  const toggleNew = () =>{
    setNew(!isNew);
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const goEntry = (entrySelected) =>{
    let day = moment(entrySelected.data().created_at.toDate()).add(entrySelected.data().timeout, 'minutes');
    let today = moment(new Date());
    if (day < today){
      setMessageAlert("Este acceso a expirado");
      return;
    }
    allData.entrySelected = entrySelected;
    navigate('/entryd');
  }

  /*const newEntryZone = () =>{
    let result = <div>
      <BtnSinglePrincipal label={"NUEVA"} click={() => toggleNew()}/>
    </div>;
    if (isNew){
      let magicButtonZone = <div></div>;
      if (gymSelected !== ""){
        magicButtonZone = <BtnMain label={"Asistir"} click={() => handleShow() }/>
      }
      result = <div>
        <SelectorEntry tValue={gymSelected} 
          oChange = {setGymSelected}
          options = {gyms}/>
        {magicButtonZone}
      </div>
    }
    return result;
  }*/

  const activeEntryZone = () =>{
    let result = <div>
      <LabelTitle>No cuentas con entradas activas.</LabelTitle>
    </div>;
    if (isLoading){
      result = <Spinner animation="grow" style={{ width: '2rem', height:'2rem', margin: '1rem' }}/>;
    }
    if (entrys.length > 0){
      result = <div className="container d-flex flex-column justify-content-center align-items-center flex-wrap">
        {
            entrys.map((entry) =>(
              <BubbleCard key={entry.id} onClick={() =>goEntry(entry)}>
                <label className="card-title">{"Sucursal "+entry.data().gym_name}</label>
                <p className="card-text">{getDayLabel(entry)}</p>
              </BubbleCard>
            ))
          }
        
      </div>;
    }
    return result;
  }

  const getDayLabel = (entry) =>{
    let cosa = entry.data().created_at.toDate();
    return moment(cosa).format('DD/MM/YYYY - h:mm a');
  }

  const getModalDesc = () =>{
    let result = "Estas a punto de solicitar acceso al gimnasio:" 
      + getname()
      + ", la duracion para el codigo generado es de 30 segundos como maximo";

    return result;
  }
  const getModalButton = () =>{
    let result = <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cancelar
      </Button>
      <Button variant="primary" onClick={createEntry}>
        Confirmar
      </Button>
    </Modal.Footer>;
    if (isLoading){
      result = <Modal.Footer>
        <Spinner animation="grow" style={{ width: '2rem', height:'2rem', margin: '1rem' }}/>
      </Modal.Footer>;
    }
    return result;
  }
  const getname = () =>{
    let result = "";
    if (gyms && gyms.length > 0 && gymSelected !== ""){
      let theGym = gyms.filter(gym => gym.id === gymSelected);
      result = theGym[0].data().name;
    }
    return " "+result;
  }

  return (
    <Base>
      <HNormal withBack/>
      <div className="container" >
        <Alert show={(messageAlert.length > 3)} variant='warning' dismissible onClose={() => setMessageAlert('')}>
          {messageAlert}
        </Alert>
        <Alert show={(messageAlertD.length > 3)} variant='danger' dismissible onClose={() => setMessageAlertD('')}>
          {messageAlertD}
        </Alert>
      </div>
      
      <Bubble withHeader="Entradas">
        <BubbleEntrys>
          {activeEntryZone()}
        </BubbleEntrys>

      </Bubble >

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
        <Modal.Title>Confirmacion</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getModalDesc()}</Modal.Body>
        {getModalButton()}
      </Modal>

    </Base>
  );
}

export default Entrys;

const Base = styled.div`
`;

const LabelTitle = styled.div`
  color: ${COLORS.blue_dark};
  font-weight: bold;
  margin: 1rem;
`;

const Tittled = styled.label`
  margin: 1.5rem 0rem 0.5rem 0rem;
  width: auto;
  font-size: 1.5em;
  font-weight: bold;
  color: ${COLORS.blue_dark};
`;

const BubbleEntrys = styled.div`
  width: 90%;
  max-width: 500px;
  height: 50%;
  max-height: 450px;
  margin: 10px auto;
  padding: 0.5rem 0rem ;
  background: lightblue;
  border-radius: 5px;
  border: 2px solid ${COLORS.green};
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;

`;

const BubbleCard = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 0.5rem;
  margin: 0.5rem 0rem;
  border-radius: 15px;
  border: 2px solid ${COLORS.green};
  background: ${COLORS.white};
  cursor: pointer;

  label{
    font-weight: bold;
    color: ${COLORS.blue_dark};
  }
  p{
    color: ${COLORS.blue_dark};
  }
`;