import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { FullContext } from '../../FullContext';
import styled from 'styled-components';
import GymInfo from './GymInfo';

import { HNormal } from '../ui/Headers';
import {COLORS} from '../ui/Colors';
import { Spinner } from 'react-bootstrap';

import {db} from '../../services/Firebase';
import {collection, getDocs, query, where} from 'firebase/firestore';

function Gyms (){
  const [gyms, setGyms] = useState([]);
  const gymsRef = collection(db, "gym");

  useEffect(() => {
    getGyms();
  }, []);

  const getGyms = async () =>{
    let q = query(gymsRef, where("active", "==", true));
    getDocs(q)
      .then(res =>{
        setGyms(res.docs);
      });
  }

  const show =() =>{
    if (gyms.length === 0){
      return <Spinner animation="grow" style={{ width: '2rem', height:'2rem', margin: '1rem' }}/>
    }else{
      return <div className="container d-flex justify-content-center flex-wrap">
        {
          gyms.map((littleGym) =>(
            <GymInfo info={littleGym} />
          ))
        }
      </div>
    }
  }

  return (
    <Base>
      <HNormal withBack/>
      <div className="contenedor">
        <LabelTitle> Sucursales: </LabelTitle>
        <hr />
        {show()}
      </div>
    </Base>
  );

}

export default Gyms;

const Base = styled.div`
  background: ${COLORS.white};
`;

const LabelTitle = styled.div`
  color: ${COLORS.black};
  font-size: 24px;
  margin-bottom: 1rem;
`;