import React from 'react';
import {FullProvider} from './FullContext';
import { Toaster } from 'sonner';
import { 
  BrowserRouter,
  Routes,
  Route 
} from "react-router-dom";
import RequireAuth  from './components/util/requireAuth';
import Login from './components/login/Login';
//import Login2 from './components/login/Login2';
import Main from './components/main/Main';
//import Main2 from './components/main/Main2';
//import Register from './components/login/Register';
import Register2 from './components/login/Register2';
import Gym from './components/gym/gym';
import Gyms from './components/gym/Gyms';
import Entry from './components/entry/Entry';
import Entrys from './components/entry/Entrys';
import EntryD from './components/entry/EntryDetail';
import Checker from './components/checker/Checker';
import ActivateList from './components/users/ActivateList';
import Account from './components/account/Account';
import QuickTest from './components/util/quickTest';
import Dashboard from './components/users/ActivateDashboard';

function App() {
  return (
    <FullProvider>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/main"
              element={
                <RequireAuth>
                  <Main />
                </RequireAuth>
              }
            />
            <Route path="/register2" element={<Register2 />}/>
            <Route path="/gym/:name"
              element={
                <RequireAuth>
                  <Gym />
                </RequireAuth>
              }
            />
            <Route path="/gyms"
              element={
                <RequireAuth>
                  <Gyms />
                </RequireAuth>
              }
            />
            <Route path="/entry"
              element={
                <RequireAuth>
                  <Entry />
                </RequireAuth>
              }
            />
            <Route path="/entrys"
              element={
                <RequireAuth>
                  <Entrys />
                </RequireAuth>
              }
            />
            <Route path="/entryd"
              element={
                <RequireAuth>
                  <EntryD />
                </RequireAuth>
              }
            />
            <Route path="/checker/:id"
              element={
                <RequireAuth>
                  <Checker />
                </RequireAuth>
              }
            />
            <Route path="/dashboard"
              element={
                <RequireAuth>
                  <ActivateList />
                </RequireAuth>
              }
            />
            <Route path="/users" element={<Login />} />
            <Route path="/account"
              element={
                <RequireAuth>
                  <Account />
                </RequireAuth>
              }
            />
            <Route path="/reallylongnamequicktestimposiburu" element={<QuickTest />} />
            <Route path="/userdashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
     </div>
    </FullProvider>
  );
}

export default App;
