import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

function EditableField(props){
  
  const { onSave, user, field } = props;
  
  const [ isSaving, setSaving ] = useState(false);
  const [ value, setValue ] = useState('');
  const [ editing, setEditing ] = useState(false);
  
  const onToggleEdit = () => {
    setEditing(!editing);
  };

  const handleSave = async () => {
    setSaving(true);
    await onSave(value, user);
    setSaving(false);
    setEditing(false);
  };

  useEffect(() => {
    if (editing) {
      setValue(user[field]);
    }
  }, [editing]);

  if (isSaving) {
    return '⏳';
  }
  return(
    <Container>
      <>
      {
        !editing ?
        <Span>
          {
            user[field] 
          }
        </Span> :
        <Input
          type='text'
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
      }
      </>
      <Button onClick={!editing ? onToggleEdit : handleSave}>
      {
        editing ?
          <>✔️Guardar</> :
          <>✎Editar</>
      }
      </Button>
    </Container>
  );

}

export default EditableField;

const Container = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Button = styled.button`
  font-size: 10px;
  width: 80%;
`;

const Span = styled.span`
  font-size: 14px;
`;

const Input = styled.input`
  font-size: 12px;
`;