import React, {useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import { FullContext } from '../../FullContext';
import CHeader from '../ui/CHeader';

import {Alert, Button, Modal, Spinner } from 'react-bootstrap';
import { addQREntry } from '../../services/qr/QrService';

import styled from 'styled-components';
import {COLORS} from '../ui/Colors';
import { HNormal } from '../ui/Headers';
import Bubble from '../ui/Bubble';
import {BtnP, BtnIngresar} from '../ui/Buttons';
import logo from '../../header_logo2_full.png';

import {BsTelephone, BsWhatsapp }  from "react-icons/bs";
import {HiOutlineMail} from "react-icons/hi";
import BlockBack from '../util/blockBack';

function Gym(){
  const navigate = useNavigate();
  const [user, setUser] = useContext(FullContext);
  const [allData, setAllData] = useContext(FullContext);
  const [showAddress, setShowA] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const faceImg = "https://seeklogo.com/images/F/facebook-icon-circle-logo-09F32F61FF-seeklogo.com.png";
  const instaImg = "https://seeklogo.com/images/I/instagram-new-2016-logo-D9D42A0AD4-seeklogo.com.png";
  const tiktokImg = "https://seeklogo.com/images/T/tiktok-icon-logo-1CB398A1BD-seeklogo.com.png";

  const goQr = async () =>{
    setLoading(true);
    const userData = {
      id: user.id,
      name: user.data().name,
    };
    const gymData = {
      id: allData.gymS.id,
      name: allData.gymS.data().name,
    };
    await addQREntry(userData, gymData, 0.5); // TODO, define the timeout in a constants file
    goNext();
  }
  const goNext = () =>{
     setLoading(false);
     navigate('/entrys');
  }

  const getname = () =>{
    if (allData && allData.gymS){
      return " "+allData.gymS.data().name;
    }
  }
  const getModalHeader = () =>{
    let result = "Confirmación"
    if (!user.data().isActive){
      result = "Atención";
    }
    return result;
  }
  const getModalDesc = () =>{
    let result = "Estas a punto de solicitar acceso al gimnasio:" 
      + getname()
      + ", la duracion para el codigo generado es de 30 segundos como maximo";

    if (!user.data().isActive){
      result = "Su memebresia se encuentra INACTIVA, favor de pasar a gerencia para habilitar su cuenta.";
    }
    return result;
  }
  const getModalButton = () =>{
    let result = <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cancelar
      </Button>
      <Button variant="primary" onClick={goQr}>
        Confirmar
      </Button>
    </Modal.Footer>;
    if (!user.data().isActive){
      result = <Modal.Footer>
    </Modal.Footer>;
    }
    if (loading){
      result = <Modal.Footer>
        <Spinner animation="grow" style={{ width: '2rem', height:'2rem', margin: '1rem' }}/>
      </Modal.Footer>;
    }
    return result;
  }

  const openPhone = () =>{
    window.open("tel:52"+allData?.gymS?.data().c_phone);
  }
  const openWhats = () =>{
    window.open("https://api.whatsapp.com/send?phone=52"+allData?.gymS?.data().c_phone);
  }
  const openMail = () =>{
    window.open("mailto:everydaygym247@gmail.com"+ allData?.gymS?.data().c_email);
  }

  const getMapFrame = () =>{
    let result = <div><h3>Buscando...</h3></div>;
    if (allData?.gymS?.data().gps_url){
      result = <MapIFrame 
        src= {allData?.gymS?.data().gps_url}
        loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
    }
    return result;
  }

  return (

    <div >
      <HNormal withBack/>

      <Bubble withoutLogo  withHeader={"Sucursal "+getname()} >
        <InfoHeader>Ubicación</InfoHeader><br />
        <InfoDesc>{allData?.gymS?.data().address}</InfoDesc><br />

        <MapFrame >
          {getMapFrame()}
        </MapFrame>

        <BtnIngresar click={() =>handleShow()} label={"Ingresar"}/>
      </Bubble>


      <Bubble >
        
        <InfoHeader>Contacto</InfoHeader><br />
        <FollowZone >
          <ImagePointer2>
            <BsTelephone size={'2em'} color={COLORS.blue_dark} onClick={openPhone}/>
          </ImagePointer2>
          <ImagePointer2>
            <BsWhatsapp size={'2em'} color={COLORS.blue_dark} onClick={openWhats} />
          </ImagePointer2>
          <ImagePointer2>
            <HiOutlineMail size={'2em'} color={COLORS.blue_dark} onClick={openMail}/>
          </ImagePointer2>
        </FollowZone>
        <InfoHeader>Siguenos</InfoHeader>
        <FollowZone >
          <ImagePointer src={faceImg} onClick={() =>{ window.open("https://www.facebook.com/Everydaygym247/")}} />
          <ImagePointer src={instaImg} onClick={() =>{ window.open("https://www.instagram.com/Everydaygym247/")}} />
          <ImagePointer src={tiktokImg} onClick={() =>{ window.open("https://www.tiktok.com/@everydaygym247")}} />
        </FollowZone>
      </Bubble>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{getModalHeader()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{getModalDesc()}</Modal.Body>
        {getModalButton()}
      </Modal>
      {/* <BlockBack /> */}
    </div>
  );
}

export default Gym;

const Tittled = styled.label`
  margin: 1.5rem 0rem 0.5rem 0rem;
  width: auto;
  font-size: 1.5em;
  font-weight: bold;
  color: ${COLORS.blue_dark};
`;

const Logo = styled.div`
  img {
    width: 80%;
    max-width: 250px;
  }
  label{
    font-weight: bold;
    color: ${COLORS.blue_dark};
  }
`;

const InfoHeader = styled.label`
  font-size: 0.9em;
  font-weight: bold;
  color: ${COLORS.blue_dark};
  text-transform: uppercase;
`;

const InfoDesc = styled.label`
  max-width: 300px;
  width: 90%;
  font-size: 0.75em;
  color: ${COLORS.blue_dark};
`;

const FollowZone = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.25rem 0rem;
`;

const ImagePointer = styled.img`
  width: 2rem;
  margin: 0rem 1rem;
  cursor: pointer;
`;

const ImagePointer2 = styled.div`
  width: 2rem;
  margin: 0rem 1rem;
  cursor: pointer;
`;

const MapFrame = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 200px;
  margin: 0.5rem 0rem;
`;

const MapIFrame = styled.iframe`
  width: 90%;
  height: 200px;
  border: 3px solid ${COLORS.blue_dark};
  border-radius: 8px;

`;
