import React, {useState, useContext} from 'react';
import {COLORS} from '../ui/Colors';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import {BtnP, BtnS} from '../ui/Buttons';

export const ModalSimple = (props) =>{

  return <Modal show={props.show} onHide={props.handleClose} centered>
  <Modal.Header closeButton>
    <Title>{props.title}</Title>
  </Modal.Header>
  <Body>{props.body}</Body>
  <Modal.Footer>
    <BtnP click={props.handleClose} label={"Aceptar"} />
  </Modal.Footer>
</Modal>;
};

const Title = styled.label`
  font-size: 1.5em;
  font-weight: bold;
  color: ${COLORS.blue_dark};
`;

const Body = styled.label`
  font-size: 1em;
  margin: 0.5rem 1rem;
  color: ${COLORS.blue_dark};
`;