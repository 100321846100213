import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { FullContext } from '../../FullContext';
import styled from 'styled-components';
import {Alert } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import {COLORS} from '../ui/Colors';
import { HLogin } from '../ui/Headers';
import Bubble from '../ui/Bubble';
import { InputLogin, SelectorGym } from '../ui/Inputs2';
import {BtnP} from '../ui/Buttons';

import { createUser, getUser, saveUser } from '../../services/users/UsersService';
import { getActiveGyms } from '../../services/gyms/GymsService';
import { getMessage } from '../util/messages';

function Register(){
  const navigate = useNavigate();
  const [user, setUser] = useContext(FullContext);
  const [allData, setAllData] = useContext(FullContext);

  const [gyms, setGyms] = useState([]);

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [pass, setPass] = useState('');
  const [passR, setPassR] = useState('');
  const [name, setName] = useState('');
  const [indexS, setIndexS] = useState('');
  const [loading, setLoading] = useState(false);

  const [messageAlert, setMessageAlert] = useState('');
  const [messageAlertD, setMessageAlertD] = useState('');

  useEffect(() => {
    getGyms();
  }, []);

  const getGyms = async () =>{
    if (allData.gym != null){
      setGyms(allData.gym);
      return;
    }
    const activeGyms = await getActiveGyms();
    setGyms(activeGyms);
    allData.gym = activeGyms;
  }

  const checkRegister = async () =>{

    var phoneno = /^\d{10}$/;
    if (email.length < 3 && !user.email){
      setMessageAlert('Necesitas un correo valido');
      return;
    }
    if (pass.length < 3 && !user.email){
      setMessageAlert('Necesitas una contraseña valida');
      return;
    }
    if (passR.length < 3 && !user.email){
      setMessageAlert('Necesitas confirmar la contraseña');
      return;
    }
    if (pass.localeCompare(passR) !== 0 && !user.email){
      setMessageAlert('La confirmacion de contraseña es incorrecta');
      return;
    }
    if (name.length < 3 ){
      setMessageAlert('Necesitas un nombre valido para representarte');
      return;
    }
    if (phone.length < 10  || !phone.match(phoneno)){
      setMessageAlert('Necesitas un telefono valido con lada');
      return;
    }
    if (indexS == ''){
      setMessageAlert('Necesitas seleccionar una sucursal');
      return;
    }

    setLoading(true);

    let branchD = {};
    branchD.id = indexS;
    branchD.name = (gyms.find((gym) => gym.id === indexS)).data().name;

    let newUser = {
      email: email,
      pass: pass
    }; 
    let newUserF = {
      created_at: new Date(),
      email: email,
      name: name,
      phone: phone,
      branch: branchD
    }
    let docRef;
    
    if (user.email){
      docRef = getUser(user.id);
      newUserF.email = user.email;
      try {
        const user = await saveUser(docRef, newUserF);
        setUser(user);
        navigate("/main");
        setLoading(false);
      } catch(error) {
        setMessageAlertD('error: '+error.message);
        setLoading(false);
      } 
      setLoading(false);
    } else {
      try {
        docRef = await createUser(newUser);
        const user = await saveUser(docRef, newUserF);
        setUser(user);
        navigate("/main");
        setLoading(false);
      } catch(error) {
        setMessageAlertD('error: '+getMessage(error.code));
        setLoading(false);
      } 
      
    }
  }

  const getEmailZone = () =>{
    if (user.email){
      return <div className="container">
        <h5>{user.email}</h5>
      </div>;
    }else{
      return <InputLogin tType="email" 
        tValue={email} 
        oChange={setEmail}
        placeholder="Correo"
      />;
    }
  }

  const getPasswordZone = () =>{
    let result = <div></div>;
    if (!user.email){
      result = <div>
        <InputLogin tType="password" 
          tValue={pass} 
          oChange={setPass}
          placeholder="Contraseña"
        />
        <InputLogin tType="password" 
          tValue={passR} 
          oChange={setPassR}
          placeholder="Confirmar Contraseña"
        />
      </div>;
    }
    return result;
  }

  const loaderZone = () =>{
    return <div className="container" >
      <Spinner animation="border" variant="info"  />
    </div>;
  }

  return (
    <Base>
      <HLogin withBack/>
      <div className="container" >
        <Alert show={(messageAlert.length > 3)} variant='warning' dismissible onClose={() => setMessageAlert('')}>
          {messageAlert}
        </Alert>
        <Alert show={(messageAlertD.length > 3)} variant='danger' dismissible onClose={() => setMessageAlertD('')}>
          {messageAlertD}
        </Alert>
      </div>
      <Bubble withHeader="Registro">
        {getEmailZone()}
        {getPasswordZone()}
        <InputLogin tType="text" 
          tValue={name} 
          oChange={setName}
          placeholder="Nombre"
        />
        <InputLogin tType="tel" 
          tValue={phone} 
          oChange={setPhone}
          placeholder="Telefono"
        />
        <SelectorGym 
          tValue={indexS}
          oChange={setIndexS}
          options={gyms}
          placeholder="Sucursal"
        />
        <br />
        {loading?loaderZone():<BtnP label={"Registrar"} click={() => checkRegister()} />}
        
      </Bubble> 

      <div className="contenedor">
        
      </div>
      
    </Base>
  );
}

export default Register;

const Base = styled.div`
  
`;

const Access = styled.label`
  margin: 1.5rem 0rem 0.5rem 0rem;
  width: auto;
  font-size: 1.5em;
  font-weight: bold;
  color: ${COLORS.blue_dark};
`;