import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { FullContext } from '../../FullContext';
import { useAuthState } from "react-firebase-hooks/auth";

import {HNormal} from '../ui/Headers';
import Bubble from '../ui/Bubble';
import {BtnP, BtnS, BtnPFull} from '../ui/Buttons';
import styled from 'styled-components';
import {COLORS} from '../ui/Colors';
import logo from '../../header_logo2_full.png';

import { getActiveGyms } from '../../services/gyms/GymsService';
import { logoutUser, getUserInfo } from '../../services/users/UsersService';
import {auth} from '../../services/Firebase';

function Main (){
  const navigate = useNavigate();
  const [user, setUser] = useContext(FullContext);
  const [allData, setAllData] = useContext(FullContext);
  const [gyms, setGyms] = useState([]);
  const [authUser] = useAuthState(auth);

  useEffect(() => {
    getGyms();
    checkUserInfo();
  }, []);

  const getGyms = async () =>{
    if (allData.gym != null){
      setGyms(allData.gym);
      return;
    }
    const activeGyms = await getActiveGyms();
    setGyms(activeGyms);
    allData.gym = activeGyms;
  }

  const checkUserInfo = async () =>{
    if (!user && authUser){
      try{
        const result = await getUserInfo(authUser.uid);
        setUser(result);
      }catch( error ) {
        console.log(error);
      }
    }
  }

  const logout = async() =>{
    await logoutUser();
    setUser({});
    setAllData({});
    navigate('/login');
  }

  const goGym = (gymSelected) =>{
    allData.gymS = gymSelected;
    navigate('/gym/'+gymSelected.data().name);
  }

  const goAccount = () =>{
    navigate('/account');
  }

  const getname = () =>{
    if (user && user.id){
      const data = user.data();
      return data ? " "+ data.name : "";
    }
  }

  return (
    <div>
      <HNormal withLogout logout={() =>logout()} account={()=>goAccount()}/>
      <Bubble withHeader="Sucursales">
        
        <Wellcome>Bienvenido</Wellcome>
        <br /> 
        <Wellcome2>{getname()}</Wellcome2>
        <br />
        <WellcomeDesc>¿En qué sucursal vas a entrenar hoy?</WellcomeDesc>

        <BubbleSuc>
          <div className="container d-flex justify-content-center flex-wrap">
            {
              gyms.map((littleGym) =>(
                <BubbleCard key={littleGym.id} onClick={() =>goGym(littleGym)}>
                  <img src={logo} alt="" />
                  <label>{"Sucursal "+littleGym.data().name}</label>
                </BubbleCard>
              ))
            }
          </div>

        </BubbleSuc>

        <BtnPFull click={() =>navigate('/entrys')} label={"Ver Entradas"}></BtnPFull>
        
      </Bubble>

      
    </div>
  );
}

export default Main;

const Tittled = styled.label`
  margin: 1.5rem 0rem 0.5rem 0rem;
  width: auto;
  font-size: 1.5em;
  font-weight: bold;
  color: ${COLORS.blue_dark};
`;

const Wellcome = styled.label`
  margin: 0rem;
  width: auto;
  font-size: 1.25em;
  font-weight: bold;
  color: ${COLORS.green};
`;
const Wellcome2 = styled(Wellcome)`
  margin-top: -0.75rem;
`;

const WellcomeDesc = styled.label`
  margin: 0.25rem;
  //max-width: 250px;
  width: 90%;
  color: ${COLORS.blue_dark};
  font-size: 0.9em;
`;

const BubbleSuc = styled.div`
  width: 90%;
  max-width: 500px;
  height: 40%;
  max-height: 400px;
  margin: 0.5rem auto;
  padding: 0.5rem 0rem ;
  background: ${COLORS.another_blue};
  border-radius: 15px;
  border: 2px solid ${COLORS.blue_dark};
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;

`;

const BubbleCard = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  margin: 0.5rem 0rem;
  border-radius: 15px;
  border: 2px solid ${COLORS.blue_dark};
  background: ${COLORS.white};
  cursor: pointer;

  img {
    width: 75%;
    max-width: 250px;
  }
  label{
    font-weight: bold;
    font-size: 0.75em;
    color: ${COLORS.blue_dark};
    text-transform: uppercase;
  }
`;

