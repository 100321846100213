import React from 'react';
import styled from 'styled-components';
import {COLORS} from '../ui/Colors';

export const BtnPrimary = ({click, label}) => {
	return (
		<Boton onClick={click}>
			{label}
		</Boton>
	);
}
export const BtnSecondary = ({click, label}) => {
	return (
		<BotonSecondary onClick={click}>
			{label}
		</BotonSecondary>
	);
}

export const BtnMain = ({click, label}) =>{
  return(
    <BotonMainPrincipal onClick={click}>
      {label}
    </BotonMainPrincipal>
  );
}
export const BtnMainSecondary = ({click, label}) =>{
  return(
    <BotonMainSecondary onClick={click}>
      {label}
    </BotonMainSecondary>
  );
}
export const BtnSinglePrincipal = ({click, label}) =>{
  return(
    <BotonSinglePrincipal onClick={click}>
      {label}
    </BotonSinglePrincipal>
  );
}

export const BtnP = ({click, label}) =>{
  return(
    <ButtonP onClick={click}>
      {label}
    </ButtonP>
  );
}
export const BtnS = ({click, label}) =>{
  return(
    <ButtonS onClick={click}>
      {label}
    </ButtonS>
  );
}

export const BtnPFull = ({click, label}) =>{
  return(
    <ButtonPFull onClick={click}>
      {label}
    </ButtonPFull>
  );
}

export const BtnIngresar = ({click, label}) =>{
  return(
    <ButtonSpecial onClick={click}>
      {label}
    </ButtonSpecial>
  );
}

export const BtnDashboard = ({click, label}) =>{
  return(
    <ButtonDash onClick={click}>
      {label}
    </ButtonDash>
  );
}
export const BtnDashboardTable = ({click, label}) =>{
  return(
    <ButtonDashTable onClick={click}>
      {label}
    </ButtonDashTable>
  );
}
export const BtnDashboardUpdate = ({click, label}) =>{
  return(
    <ButtonDashActive onClick={click}>
      {label}
    </ButtonDashActive>
  );
}
export const BtnDashboardUpdate2 = ({click, label}) =>{
  return(
    <ButtonDashInactive onClick={click}>
      {label}
    </ButtonDashInactive>
  );
}

const Boton = styled.button`
  width: 90%;
  margin-bottom: 1rem;
	background: #0BBBEF;
	color: #fff;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 70px;
  vertical-align: top;
  font-size: 24px;
  font-weight: bold;
	text-align: center;
	border: 2px solid rgb(22,140,186);
	border-radius: 30px;
	cursor: pointer;
	transition: all .3s ease;
	&:hover {
    background: rgba(22,140,186, .50);
    border: 2px solid rgba(22,140,186, .50);
	}
`;

const BotonSecondary = styled(Boton)`
  background: none;
  border: none;
  color: #0BBBEF;
  &:hover {
    background: rgba(22,140,186, .50);
    border: 2px solid rgba(22,140,186, .50);
    color: #fff;
	}
`;

const BotonMainPrincipal = styled.button`
  width: 90%;
  margin-bottom: 1rem;
  background: ${COLORS.blue_dark};
  color: ${COLORS.white};
  display: inline-flex;
	align-items: center;
	justify-content: center;
  height: 90px;
  font-size: 22px;
  text-align: center;
  border-radius: 30px;
  cursor: pointer;
  transition: all .3s ease;
`;

const BotonMainSecondary = styled(BotonMainPrincipal)`
  background: none;
  border: none;
  height: 70px;
  color: ${COLORS.blue_dark};
 
`;

const BotonSinglePrincipal = styled(BotonMainPrincipal)`
  margin-top: 1rem;

`;

const ButtonP = styled.button`
  width: 45%;
  max-width: 250px;
  min-width: 100px;
  height: auto;
  min-height: 2rem;
  max-height: 4rem;
  background: ${COLORS.blue_light};
  border: 2px solid ${COLORS.green};
  border-radius: 5px;
  color: ${COLORS.green};
  font-weight: bold;
`;
const ButtonS = styled(ButtonP)`
  background: ${COLORS.green};
  color: ${COLORS.white};
  margin-left: 0.5rem;
`;

const ButtonPFull = styled.button`
  width: 90%;
  max-width: 500px;
  min-width: 200px;
  height: auto;
  min-height: 2rem;
  max-height: 4rem;
  background: ${COLORS.blue_dark};
  border: 2px solid ${COLORS.blue_dark};
  border-radius: 8px;
  color: ${COLORS.white};
  font-weight: bold;
  font-size: 0.9rem;
`;

const ButtonSpecial = styled.button`
  width: 70%;
  max-width: 400px;
  min-width: 200px;
  height: auto;
  min-height: 2rem;
  max-height: 4rem;
  background: ${COLORS.orange};
  border: 2px solid ${COLORS.orange};
  border-radius: 8px;
  color: ${COLORS.white};
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  font-size: 1.25em;
  margin: 0.5rem;
`;

const ButtonDash = styled.button`
  width: 80%;
  height: 3rem;
  border: 2px solid ${COLORS.green};
  border-radius: 5px;
  font-weight: bold;
  background: ${COLORS.green};
  color: ${COLORS.white};
`;

const ButtonDashTable = styled(ButtonDash)`
  height: 1.5rem;
  background: ${COLORS.blue_light};
  border: 2px solid ${COLORS.green};
  color: ${COLORS.green};
  transition: all .3s ease;
	&:hover {
    background: ${COLORS.green};
    color: ${COLORS.white};
	}
`;

const ButtonDashActive = styled(ButtonDash)`
  background: ${COLORS.blue_light};
  border: 2px solid ${COLORS.green};
  color: ${COLORS.green};
`;

const ButtonDashInactive = styled(ButtonDash)`
  background: ${COLORS.blue_light};
  border: 2px solid ${COLORS.red};
  color: ${COLORS.red};
`;
