import React from 'react';
import {COLORS} from '../ui/Colors';
import styled from 'styled-components';

import logo from '../../logo_logo2.png';

function Bubble(props){

  const getBase = () =>{
    let result = <div></div>;
    let headerT = <div></div>;
    if (props.withHeader){
      if (props.bigBubble){
        headerT = <BaseBigHeader>
          <Tittled>{props.withHeader}</Tittled>
        </BaseBigHeader>
      }else{
        headerT = <BaseHeader>
          <Tittled>{props.withHeader}</Tittled>
        </BaseHeader>
      }
    }

    if (!props.withoutLogo){
      if (props.bigBubble){
        result = <BaseBig>
          {headerT}
          {props.children}
        </BaseBig>;
      }else{
        result = <Base>
        {headerT}
        {props.children}
      </Base>;
      }
    }else{
      if (props.bigBubble){
        result = <BaseBigNologo>
          {headerT}
          {props.children}
        </BaseBigNologo>;
      }else{
        result = <BaseNologo>
          {headerT}
          {props.children}
        </BaseNologo>;
      }
      
    }
    return result;
  }
  const getLogo = () =>{
    let result = <div></div>
    if (!props.withoutLogo){
      let cEvent = props.logOut? props.logOut:() =>{};

      result = <Logo>
        <img src={logo} alt="" onClick={cEvent}/>
      </Logo>;
    }
    return result;
  }

  return(
    <div>
      {getBase()}
      {getLogo()}
    </div>
  );

}

export default Bubble;

const Base = styled.div`
  max-width: 600px;
  width: 90%;
  margin: 10px auto;
  padding: 0.5rem 0rem 2rem 0rem;
  background: white;
  border-radius: 15px;
  
`;

const BaseHeader = styled.div`
  max-width: 600px;
  width: 100%;
  margin: -0.5rem 0rem 0.5rem 0rem;
  background: ${COLORS.blue_dark};
  border-radius: 15px 15px 0px 0px;
`;

const Logo = styled.div`  
  margin: -2.5rem 0rem 0rem 0rem;
	img {
		width: 75px;
	}
`;

const BaseNologo = styled(Base)`
  padding: 0.5rem 0rem;
`;

const Tittled = styled.label`
  margin: 0.5rem 0rem;
  width: auto;
  font-size: 1em;
  font-weight: bold;
  color: ${COLORS.white};
  text-transform: uppercase;
`;

const BaseBig = styled(Base)`
  max-width: 80%;
`;

const BaseBigHeader = styled(BaseHeader)`
  max-width: 100%;
`;

const BaseBigNologo = styled(BaseBig)`
  padding: 0.5rem 0rem;
`;