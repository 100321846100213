import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

function ListDropdown(props){
  
  const { elements, onSave, user, field } = props;
  
  const [ selectedElement, setSelectedElement ] = useState('');
  
  const options = elements.map(elem => {
    const data = elem.data();
    return <Option key={elem.id} value={elem.id}>{data.name}</Option>
  });

  useEffect(() => {
    if (elements.length > 0 && !user[field]) {
      setSelectedElement(elements[0].id);
    }
    if (elements.length > 0 && user[field]) {
      const item = user[field];
      const selectedId = item ? item.id: '';
      setSelectedElement(selectedId);
    }
  }, [elements, user]);

  const onSelect = (e) => {
    setSelectedElement(e.target.value);
  }

  const onClick = (e) => {
    const element = elements.find((e) => e.id === selectedElement);
    onSave(element, user);
  };
  return(
    <Container>
      <Select value={selectedElement} onChange={onSelect}>
        {options}
      </Select>
      <Button onClick={onClick}>
      ✔️Guardar
      </Button>
      <>
      {
        field !== '' &&
        <Span>
          {
            user[field] ? 
              `Actual: ${user[field].name}` :
              'Sin asignar'
          }
        </Span>
      }
      </>
    </Container>
  );

}

export default ListDropdown;

const Container = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const Select = styled.select`
  font-size: 12px;
`;

const Option = styled.option`
  font-size: 12px;
`;

const Button = styled.button`
  font-size: 12px;
`;

const Span = styled.span`
  font-size: 10px;
`;