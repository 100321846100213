import React from 'react';
import styled from 'styled-components';
import {COLORS} from './Colors';

export const InputLogin = ({tType, tValue, oChange, placeholder}) => {
	return (
    <div>
      <div>
        <HeaderLbl>{placeholder}</HeaderLbl>
      </div>
      <div>
        <Input type={tType} value={tValue} 
          onChange={(event) =>{oChange(event.target.value)}}/>
      </div>
    </div>
    
	);
}

export const SelectorGym = ({tValue, oChange, options, placeholder}) =>{
  return (
    <div>
      <div>
        <HeaderLbl>{placeholder}</HeaderLbl>
      </div>
      <div>
        <Selector value={tValue}
          onChange={(event) =>{oChange(event.target.value)}}>
          <option value="">Seleccione</option>
            {options.map(gym =>(
              <option value={gym.id}>{gym.data().name}</option>
            ))}
        </Selector>
      </div>
    </div>
  );
}

export const InputDisabled = ({tValue, placeholder}) => {
	return (
    <div>
      <div>
        <HeaderLbl>{placeholder}</HeaderLbl>
      </div>
      <div>
        <HeaderLblB>{tValue}</HeaderLblB>
      </div>
    </div>
	);
}

export const InputDashboard = ({tType, tValue, oChange, placeholder}) => {
	return (
    <ContainerInputDash>
      <div>
        <InputDash type={tType} value={tValue} 
          onChange={(event) =>{oChange(event.target.value)}}/>
      </div>
      <div>
        <HeaderLbl>{placeholder}</HeaderLbl>
      </div>
    </ContainerInputDash>
	); 
}
export const InputDashboardUpdate = ({tType, tValue, oChange, placeholder}) => {
	return (
    <ContainerInputDash>
      <div>
        <HeaderLbl>{placeholder}</HeaderLbl>
      </div>
      <div>
        <InputDash type={tType} value={tValue} 
          onChange={(event) =>{oChange(event.target.value)}}/>
      </div>
    </ContainerInputDash>
	); 
}

const Input = styled.input`
  width: 90%;
  max-width: 400px;
  margin-bottom: 0.5rem;
	background: ${COLORS.blue_light};
	border: 2px solid ${COLORS.green};
	border-radius: 5px;
	
	cursor: pointer;
	transition: all .3s ease;
	&:hover {
		//border: 2px solid rgba(22,140,186, .50);
  }

`;

const HeaderLbl = styled.label`
  //margin: 1rem;
  //font-weight: bold;
  color: ${COLORS.blue_dark};
`;
const HeaderLblB = styled.label`
  //margin: 1rem;
  font-weight: bold;
  color: ${COLORS.blue_dark};
`;

const Selector = styled.select`
  width: 90%;
  height: 40px;
  max-width: 400px;
  margin-bottom: 0.5rem;
  background: ${COLORS.blue_light};
  border: 2px solid ${COLORS.green};
  border-radius: 5px;
  text-align: center;
  color: ${COLORS.green};
`;

const ContainerInputDash = styled.div`
  width: 25%;
`;

const InputDash = styled(Input)`
  margin-bottom: 0rem;

`;

