import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { FullContext } from '../../FullContext';
import {Alert } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import {HNormal} from '../ui/Headers';
import Bubble from '../ui/Bubble';
import { InputLogin, InputDisabled, SelectorGym  } from '../ui/Inputs2';
import {BtnP} from '../ui/Buttons';

import { updateUserInfo, getUserInfo } from '../../services/users/UsersService';

function Account (){

  const navigate = useNavigate();
  const [user, setUser] = useContext(FullContext);
  const [allData, setAllData] = useContext(FullContext);

  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [branch, setBranch] = useState('');
  const [gyms, setGyms] = useState([]);
  const [loading, setLoading] = useState(false);

  const [messageAlert, setMessageAlert] = useState('');

  useEffect(() => {
    setInfo();
  }, []);

  const setInfo = ()=>{
    if (user && user.id){
      setName(user.data().name);
      setPhone(user.data().phone);
      setEmail(user.data().email);
      if (user.data().branch){
        setBranch(user.data().branch.name);
      }
    }
    //setGyms(allData.gym);
  }

  const checkUpdate = async () =>{

    var phoneno = /^\d{10}$/;
    if (name.length < 3 ){
      setMessageAlert('Necesitas un nombre valido para representarte');
      return;
    }
    if (phone.length < 10  || !phone.match(phoneno)){
      setMessageAlert('Necesitas un telefono valido con lada, son 10 digitos');
      return;
    }
    /*if (branch == ''){
      setMessageAlert('Necesitas seleccionar una sucursal');
      return;
    }*/

    setLoading(true);

    //let branchD = {};
    //branchD.id = branch;
    //branchD.name = (gyms.find((gym) => gym.id === branch)).data().name;

    let newInfo = {};
    newInfo.name = name;
    newInfo.phone = phone;
    //newInfo.branch = branchD;

    await updateUserInfo(user.id , newInfo);
    let newUserUpdated = await getUserInfo(user.id);
    setUser(newUserUpdated);

    setLoading(false);
    
  }

  const loaderZone = () =>{
    return <div className="container" >
      <Spinner animation="border" variant="info"  />
    </div>;
  }

  const getBranchName = () =>{
    let result = "Sin sucursal, favor de pedir asignación."
    if (branch != '') result = branch; 
    return result;
  }

  return (
    <div>
      <HNormal withBack />
      <div className="container" >
        <Alert show={(messageAlert.length > 3)} variant='warning' dismissible onClose={() => setMessageAlert('')}>
          {messageAlert}
        </Alert>
      </div>
      <Bubble withHeader="Mis Datos">

        <InputDisabled 
          tValue={email} 
          placeholder="Correo"
        />
        <InputLogin tType="text" 
          tValue={name} 
          oChange={setName}
          placeholder="Nombre"
        />
        <InputLogin tType="tel" 
          tValue={phone} 
          oChange={setPhone}
          placeholder="Telefono"
        />
        <InputDisabled 
          tValue={getBranchName()} 
          placeholder="Sucursal"
        />

        <br />
        {loading?loaderZone():<BtnP label={"Actualizar"} click={() => checkUpdate()} />}
         
      </Bubble>
    </div>
  );
}

export default Account;

/**
  <SelectorGym 
          tValue={branch}
          oChange={setBranch}
          options={gyms}
          placeholder="Sucursal"
        />
  

 */