import {useState, useContext, useEffect} from "react";
import {useNavigate } from "react-router-dom";
//import {FullContext } from '../../FullContext';
import {Alert } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

import {getMembershipTypes } from "../../services/users/MembershipService";
import {getActiveGyms } from "../../services/gyms/GymsService";
import {getUserByField, updateUserInfo, getUserInfo } from "../../services/users/UsersService";

import {HNormal} from '../ui/Headers';
import Bubble from '../ui/Bubble';
import { InputDashboard, InputDisabled, InputLogin, SelectorGym} from '../ui/Inputs2';
import {BtnDashboard, BtnDashboardTable, BtnP, BtnS, BtnDashboardUpdate, BtnDashboardUpdate2} from '../ui/Buttons';

export default function ActivateDashboard(){
  const navigate = useNavigate();
  const [gyms, setGyms] = useState([]);
  const [membershipTypes, setMembershipTypes] = useState([]);

  const [sName, setSName] = useState('');
  const [sPhone, setSPhone] = useState('');
  const [sMail, setSMail] = useState('');
  const [users, setUsers] = useState([]);

  const [uName, setUName] = useState('');
  const [uPhone, setUPhone] = useState('');
  const [uBranch, setUbranch] = useState('');
  const [uMembership, setUMembership] = useState('');
  const [uActive, setUActive] = useState(false);

  const [loading, setLoading] = useState(false);
  const [searchOk, sSOK] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [messageAlertD, setMessageAlertD] = useState('');
  const [userUpdate, setUserUpdate] = useState(null);

  useEffect(() => {
    getGyms();
    getMemberships();
  }, []);

  const getGyms = async () =>{
    const activeGyms = await getActiveGyms();
    setGyms(activeGyms);
  }
  const getMemberships = async () =>{
    const membershipTypes = await getMembershipTypes();
    setMembershipTypes(membershipTypes);
  }

  const toggleLoading = () =>{
    setLoading(!loading)
  }
  const updateName = (data) =>{
    setSPhone('');
    setSMail('');
    setSName(data);
  }
  const updatePhone = (data) =>{
    setSPhone(data);
    setSMail('');
    setSName('');
  }
  const updateEmail = (data) =>{
    setSPhone('');
    setSMail(data);
    setSName('');
  }
  const goSearch = async () =>{
    setMessageAlert('');
    var phoneno = /^\d{10}$/;
    sSOK(false);
    if (sName.length < 3 && sName.length > 0) {
      setMessageAlert("Recuerda que son busquedas por nombre completo.")
      return;
    }
    if (sPhone.length > 0 && (sPhone.length < 10 || !sPhone.match(phoneno))) {
      setMessageAlert("Recuerda que son busquedas por telefono completo.")
      return;
    }
    if (sMail.length < 3 && sMail.length > 0) {
      setMessageAlert("Recuerda que son busquedas por correo completo.")
      return;
    }
    if (sMail.length == 0 && sPhone.length == 0 && sName.length == 0){
      return;
    }
    setLoading(true);

    let action = "name";
    let value = sName;
    if (sPhone.length > 3){
      action = "phone";
      value = sPhone;
    }
    if (sMail.length > 3){
      action = "email";
      value = sMail;
    } 

    let result = await getUserByField(value, action);
    setUsers(result.docs);
    setLoading(false);
    sSOK(true);
  }
  const goUpdate = async () =>{
    setMessageAlert('');
    var phoneno = /^\d{10}$/;
    if (uName.length < 3) {
      setMessageAlert('Necesitas un nombre valido para representarte')
      return;
    }
    if (uPhone.length < 10  || !uPhone.match(phoneno)){
      setMessageAlert('Necesitas un telefono valido con lada');
      return;
    }
    if (uBranch == ''){
      setMessageAlert('Necesitas seleccionar una sucursal');
      return;
    }
    setLoading(true);

    let branchU = {};
    branchU.id = uBranch;
    branchU.name = (gyms.find((gym) => gym.id === uBranch)).data().name;

    let newInfo = userUpdate.data();
    newInfo.name = uName;
    newInfo.phone = uPhone;
    newInfo.branch = branchU;
    newInfo.isActive = uActive;
    if (uMembership != ''){
      let membershipU = {};
      membershipU.id = uMembership;
      membershipU.name = (membershipTypes.find((member) => member.id === uMembership)).data().name;
      newInfo.membership = membershipU;
    }else if (userUpdate.data().membership){
      //borrar membresia
    }

    await updateUserInfo(userUpdate.id , newInfo);
    let newUserUpdated = await getUserInfo(userUpdate.id);
    let index = (users.findIndex((user) => user.id === userUpdate.id));
    users[index] = newUserUpdated;
    setLoading(false);
    setMessageAlertD("Usuario actualizado.");
    hideUpdate();
  }

  const showBranch = (data) =>{
    return data.data().branch?data.data().branch.name:"Sin Sucursal";
  }
  const showMembership = (data) =>{
    return data.data().membership?data.data().membership.name: "Sin Membresia";
  }
  const showActive = (data) =>{
    return data.data().isActive?"Activo": "Inactivo";
  }
  const showTable = () =>{
      let result = <Table striped bordered hover>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Correo</th>
          <th>Teléfono</th>
          <th>Sucursal</th>
          <th>Tipo Membresia</th>
          <th>Estatus</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {
        users.map((user) => {
          return <tr key={user.id}>
            <Td>{user.data().name}</Td>
            <Td>{user.data().email}</Td>
            <Td>{user.data().phone}</Td>
            <Td>{showBranch(user)}</Td>
            <Td>{showMembership(user)}</Td>
            <Td>{showActive(user)}</Td>
            <Td>
              <BtnDashboardTable label={"Modificar"} click={() => {showUpdate(user)}} />
            </Td>
          </tr>
        })
      }
      </tbody>
    </Table>;
    return result;
  }
  const showBigBubbleSearch = () =>{
    let result = <div></div>;
    if (userUpdate == null){
      result = <BaseHeader>
        <InputDashboard tType="text" 
          tValue={sName} 
          oChange={updateName}
          placeholder="Nombre"
        />
        <InputDashboard tType="text" 
          tValue={sPhone} 
          oChange={updatePhone}
          placeholder="Telefono"
        />
        <InputDashboard tType="text" 
          tValue={sMail} 
          oChange={updateEmail}
          placeholder="Correo"
        />
        <ConatinerSearchBtn>
          {loading?<Spinner animation="border" variant="info"/>:<BtnDashboard label={"Buscar"} click={() => goSearch()} />}
        </ConatinerSearchBtn>
      </BaseHeader>;
    }
    return result;
  }
  const showBigBubbleTable = () =>{
    let result;
    let message = "Listo para Buscar";
    if (loading){
      message = "Cargando...";
    }else{
      if (users.length > 0){
        result = showTable();
      }
      if (searchOk){
        message = "No se encontraron coincidencias";
      }
    }
    if (result == null) result = <div><h4>{message}</h4></div>;
    
    return result;
  }
  const showBigBubbleUpdate = () =>{
    let result = <div></div>;
    if (userUpdate){
      result = <Bubble bigBubble withoutLogo>
        <UpdateZone>
          <InnerUpdateZone>
            <InputLogin tType="text" 
              tValue={uName} 
              oChange={setUName}
              placeholder="Nombre"
            />
          </InnerUpdateZone>
          <InnerUpdateZone>
            <InputDisabled tType="text" 
              tValue={userUpdate?userUpdate.data().email:""} 
              placeholder="Correo"
            />
          </InnerUpdateZone>
          <InnerUpdateZone>
            <InputLogin tType="text" 
              tValue={uPhone} 
              oChange={setUPhone}
              placeholder="Telefono"
            />
          </InnerUpdateZone>
        </UpdateZone>
        <UpdateZone>
          <InnerUpdateZone>
            <SelectorGym 
              tValue={uBranch}
              oChange={setUbranch}
              options={gyms}
              placeholder="Sucursal"
            />
          </InnerUpdateZone>
          <InnerUpdateZone>
           <SelectorGym 
              tValue={uMembership}
              oChange={setUMembership}
              options={membershipTypes}
              placeholder="Membresia"
            />
          </InnerUpdateZone>
          <InnerUpdateZoneB>
            {uActive?<BtnDashboardUpdate label={"Activo"} click={() => setUActive(false)} />:<BtnDashboardUpdate2 label={"Inactivo"} click={() => setUActive(true)} />}  
          </InnerUpdateZoneB>
        </UpdateZone>

        <UpdateZone>
          <BtnP label={"Cancelar"} click={() => hideUpdate()} />
          <BtnS label={"Actualizar"} click={() => goUpdate()} />
        </UpdateZone>

      </Bubble>
    }
    return result;
  }
  const showUpdate = (user) =>{
    setUserUpdate(user);
    setUName(user.data().name);
    setUPhone(user.data().phone);
    setUbranch(user.data().branch?user.data().branch.id:'');
    setUMembership(user.data().membership?user.data().membership.id:'');
    setUActive(user.data().isActive);
  }
  const hideUpdate = () =>{
    setUserUpdate(null);
    setUName('');
    setUPhone('');
    setUbranch('');
    setUMembership('');
    setUActive(false);
  }

  return <div>
    <HNormal />
    <div className="container" >
      <Alert show={(messageAlert.length > 3)} variant='warning' dismissible onClose={() => setMessageAlert('')}>
        {messageAlert}
      </Alert>
      <Alert show={(messageAlertD.length > 3)} variant='success' dismissible onClose={() => setMessageAlertD('')}>
        {messageAlertD}
      </Alert>
    </div>
    <Bubble withoutLogo withHeader="Usuarios" bigBubble>
      {showBigBubbleSearch()}
    </Bubble>

    {showBigBubbleUpdate()}

    <Bubble bigBubble>
      {showBigBubbleTable()}
    </Bubble>
  </div>;
}

const BaseHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const ConatinerSearchBtn  = styled.div`
width: 25%;
display: flex;
justify-content: center;
align-items: center;
`;

const Td = styled.td`
  font-size: 14px;
`;

const UpdateZone = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const InnerUpdateZone = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const InnerUpdateZoneB =  styled(InnerUpdateZone)`
  align-items: center;
`;


