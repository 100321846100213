export const COLORS = {
  blue: '#0bbbef',
  blue_dark: '#002b39',
  white: '#fbfbfb',
  black: '#2d3436',
  green: '#177A7D',
  blue_light: '#defdfe',
  another_blue: '#335561',
  orange: '#F15A25',
  red: '#B22222',
};
