import { auth, db } from '../Firebase';
import { signOut, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import {
  getDoc,
  setDoc,
  updateDoc,
  doc,
  collection,
  orderBy,
  getDocs,
  query,
  limit,
  startAt,
  endAt,
  where,
  startAfter,
} from 'firebase/firestore';

export const createUser = async (user) => {
  const { email, pass } = user;
  const res = await createUserWithEmailAndPassword(auth, email, pass);
  const docRef = doc(db, "user", res.user.uid);
  return docRef;
};

export const getUser = async (userId) => {
  return doc(db, "user", userId);
};

export const logoutUser = async () => {
    await signOut(auth);
};

export const saveUser = async (docRef, newUser) =>{
  await setDoc(docRef,  newUser);
  const userDoc = await getDoc(docRef);
  return userDoc;
};

export const getUserList = async(
  queryLimit = 10,
  q = '',
  showInactive = false,
  lastVisible = null,
) => {
  const usersCollection = await collection(db, 'user');
  //const pageSize = 10;
  // Query the collection, starting from the last document of the previous page
  const constraints = [];
  //usersQuery = query(limit(queryLimit));
  constraints.push(orderBy('name', 'asc'));
  if (lastVisible !== null) {
    constraints.push(startAfter(lastVisible));
  }
  if (q.trim() !== '' && q.trim().length > 2) {
    constraints.push(startAt(q));
    constraints.push(endAt(q +'\uf8ff'));
  }
  constraints.push(limit(queryLimit));
  if (!showInactive) {
    constraints.push(where('isActive','==',true));
  }
  let usersQuery = query(usersCollection, ...constraints);
  const snapshot = await getDocs(usersQuery);
  const users = [];
  snapshot.forEach((doc) => {
    const data = doc.data();
    users.push({
      id: doc.id,
      ...data,
    });
  });
  const last = snapshot.docs[snapshot.docs.length - 1];
  return { usersData: users , last };
};

export const toggleActiveUser = async(user) => {
  const { isActive, id } = user;
  const active = !isActive;
  const userRef = doc(db, 'user', id);
  setDoc(userRef, { isActive: active }, { merge: true });
};

export const updateUserBranch = async(branch, user) => {
  const { id } = user;
  const userRef = doc(db, 'user', id);
  setDoc(userRef, { branch }, { merge: true });
};

export const updateUserMembershipType = async(membership, user) => {
  const { id } = user;
  const userRef = doc(db, 'user', id);
  setDoc(userRef, { membership }, { merge: true });
};

export const recoverPassword = async(email) => {
  return await sendPasswordResetEmail(auth, email);
};

export const updateUserField = async(user, field, value) => {
  const { id } = user;
  const userRef = doc(db, 'user', id);
  updateDoc(userRef, { [field]: value });
};

export const getUserInfo = async (userId) =>{
  let userRef = doc(db, "user", userId);
  const userDoc = await getDoc(userRef);
  return userDoc;
}

export const updateUserInfo = async (userId, infonew) =>{
  const userRef = doc(db, 'user', userId);
  updateDoc(userRef, infonew);
}

export const getUsersActive = async () =>{
  const q = query(collection(db, "user"), where("isActive", "==", true));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}

export const getUserByField = async (value, field) =>{
  const q = query(collection(db, "user"), where(field, "==", value));
  const querySnapshot = await getDocs(q);
  return querySnapshot;
}