import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { FullContext } from '../../FullContext';
import CHeader from '../ui/CHeader';
import {Alert} from 'react-bootstrap';
import moment from 'moment';

import { db } from '../../services/Firebase';
import {collection, getDocs, query, where} from 'firebase/firestore';

function Entry() {
  const navigate = useNavigate();
  const [user, setUser] = useContext(FullContext);
  const [allData, setAllData] = useContext(FullContext);
  const [entrys, setEntrys] = useState([]);
  const [messageAlert, setMessageAlert] = useState('');
  const [messageAlertD, setMessageAlertD] = useState('');

  const entrysRef = collection(db, "entry");

  useEffect(() => {
    getEntrys();
  }, []);

  const getEntrys = async() =>{
    let q = query(entrysRef, where("user", "==", user.id));
    getDocs(q)
      .then(res =>{
        setEntrys(res.docs);
      });
  }

  const goEntry = (entrySelected) =>{
    let day = moment(entrySelected.data().created_at.toDate()).add(entrySelected.data().timeout, 'minutes');
    let today = moment(new Date());
    if (day < today){
      setMessageAlert("Este acceso a expirado");
      return;
    }
    allData.entrySelected = entrySelected;
    navigate('/entryd');
  }

  const getDayLabel = (entry) =>{
    let cosa = entry.data().created_at.toDate();
    return moment(cosa).format('DD/MM/YYYY - h:mm a');
  }

  return(
    <CHeader title={"Accesos"} withBack>
      <div className="container" >
        <Alert show={(messageAlert.length > 3)} variant='warning' dismissible onClose={() => setMessageAlert('')}>
          {messageAlert}
        </Alert>
        <Alert show={(messageAlertD.length > 3)} variant='danger' dismissible onClose={() => setMessageAlertD('')}>
          {messageAlertD}
        </Alert>
      </div>
      <div className="container d-flex justify-content-center flex-wrap">
        {
          entrys.map((entry) =>(
            <div className="card border-primary " style={{ width: '13rem', margin: '0.5rem' }} key={entry.id} onClick={() =>goEntry(entry)}>
              <div className="card-body" >
                <h4 className="card-title">{"Sucursal "+entry.data().gym_name}</h4>
                <p className="card-text">{getDayLabel(entry)}</p>
              </div>
            </div>
          ))
        }
      </div>
    </CHeader>
  );
}

export default Entry;