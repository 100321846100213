import { db } from '../Firebase';
import { collection, addDoc } from 'firebase/firestore';

export const addQREntry = async (userData, gymData, timeout) => {
  const entryData = {
    created_at: new Date(),
    user: userData.id,
    user_name: userData.name,
    gym: gymData.id,
    gym_name: gymData.name,
    timeout
  };
  await addDoc(collection(db, "entry"), entryData);
};